.navbar-wrapper {
    margin-top: 1vh;
    padding: 1vmax;
    /* outline: 0.15vmax solid #5E6369; */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
    border-radius: 0.25rem;
}

.navbar-wrapper:hover {
    cursor: pointer;
}

.navbar-wrapper-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.nav-logo-wrapper {
    text-align: left !important;
    font-size: larger;
    /* width: 20vw; */
}

.nav-logo {
    /* object-fit: cover; */
    width: 25vh;
    /* object-fit: scale-down; */
    text-align: center;
}