.new-season-wrapper {
    /* height: 100vh; */
}

.new-season-hero-title {
    margin-left: 3vw;
    margin-right: 3vw;
    margin-top: 2vh;
    padding: 3vmax;
    font-size: 2.5vmax;
    color: white;
    font-family: 'ProductSans-Bold', serif;
    background-color: #202125;
    border-radius: 0.25rem;
    text-align: left;
    box-shadow: 0 0 0 0.3vh #202125;
}

.new-season-info-bar-wrapper {
    margin-top: 2vh;
    text-align: center;
    padding: 1vh 2vw;
    background-color: #6ea0eb;
    color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.new-season-card-wrapper {
    margin-left: 2vw !important;
    margin-right: 2vw !important;
    /* margin-top: 1vh !important; */
}

.new-season-calender-col {
    margin-top: 2vh !important;
}

.calender-wrapper {
    text-align: left;
}

.calender-date {
    color: #E57373;
    font-weight: bold;
    text-align: left;
}

.calender-session-hero {
    width: max-content;
    background-color: #E57373;
    color: white;
    border-radius: 0.8rem;
    padding: 0 2%;
}

.new-season-join-bar-wrapper {
    margin-top: 2vh !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    text-align: center;
    padding: 1vh 2vw;
    background-color: #202125;
    color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 1vmax 0;
}

.new-season-join-col {
    margin-top: 1vh !important;
    margin-bottom: 1vh !important;
}

.info-bar-register-button {
    font-size: x-large;
    color: white;
    background-color: #6ea0eb;
    border: none;
    border-radius: 0.85rem;
    padding: 1%;
    transition: 0.3s;
    width: 50%;
    /* margin-bottom: 1vh; */
    height: 100% !important;
}

.info-bar-register-button:hover {
    color: #6ea0eb;
    background-color: white;
}

.new-season-session-detail-wrapper {
    margin-top: 1vh;
}

.new-season-session-detail-tile-wrapper {
    margin-left: 3vw !important;
    margin-right: 3vw !important;
    /* background-color: #6ea0eb; */
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    border-radius: 0.85rem;
    padding: 1%;
    margin-top: 2vh !important;
}

.new-season-session-detail-tile-wrapper-col {
    margin-top: 1vh !important;
    margin-bottom: 1vh !important;
}

.new-season-session-detail-day {
    font-size: x-large;
    color: #6ea0eb;
}

.new-season-session-detail-title {
    font-size: x-large;
    color: #6ea0eb;
    transition: 0.3s;
}

.new-season-session-detail-title:hover {
    color: #6ea0eb;
}

.silent-finale-text {
    margin-top: 1vmax;
    background-color: #6ea0eb;
    width: fit-content;
    padding: 0 2%;
    border-radius: 0.75rem;
    color: white;
    word-wrap: break-word;
    transition: 0.3s;
}

.silent-finale-text:hover {
    color: #6ea0eb;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    background-color: white;
}

.reminder{
    background-color: #E57373;
}