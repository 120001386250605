.team-wrapper {
    /* height: 100vh; */
}

.theteam-hero-title {
    margin-left: 3vw;
    margin-right: 3vw;
    margin-top: 2vh;
    padding: 3vmax;
    font-size: 2.5vmax;
    color: white;
    font-family: 'ProductSans-Bold', serif;
    background-color: #6ea0eb;
    border-radius: 0.45rem;
    text-align: left;
}

.subtitle-team-hero {
    margin-top: 2vh;
    text-align: center;
    /* padding: 1vh 2vw; */
    background-color: #6ea0eb;
    color: white;
}


.theteam-core-team-row,
.theteam-org-team-row {
    margin-top: 1vh !important;
    margin-left: 3vw !important;
    margin-right: 3vw !important;
}

.theteam-core-team-col,
.theteam-org-team-col {
    margin-top: 2vh !important;
}

.theteam-core-team-tile-wrapper {
    /* background-color: #EDEDED; */
    padding: 2vmax 1.5vmax;
    /* height: 34vh; */
    text-align: center;
    /* height: 32vmax; */
    height: 100%;
    border-radius: 0.25rem;
    /* box-shadow: 0 0 0 0.3vh #202125; */
    transition: 0.3s;
    position: relative;
}

.theteam-core-team-tile-wrapper:hover {
    cursor: pointer;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    border-radius: 0.75rem;
}

.theteam-core-team-image-wrapper {
    /* height: 15vmin;
    width: 15vmin; */
    margin: auto;
    /* margin-top: 1vh; */
}

.theteam-org-team-image-wrapper {
    /* height: 15vmin;
    width: 15vmin; */
    margin: auto;
    /* margin-top: 1vh; */
}

.theteam-core-team-image-wrapper>img {
    /* width: 100%; */
    /* width: 80%;
    height: 18vh; */
    width: 20vmin;
    object-fit: cover;
    height: 20vmin;
    border-radius: 50%;
    /* object-fit: cover; */
    /* border-radius: 0.25rem; */
    /* box-shadow: 0 0 0 0.3vh #202125; */
}

.theteam-org-team-image-wrapper>img {
    /* width: 100%; */
    /* width: 80%;
    height: 18vh; */
    width: 20vmin;
    object-fit: cover;
    height: 20vmin;
    border-radius: 50%;
    /* object-fit: cover; */
    /* border-radius: 0.25rem; */
    /* box-shadow: 0 0 0 0.3vh #202125; */
}

.theteam-core-team-tile-title {
    /* width: fit-content;
    height: fit-content; */
    word-break: break-word;
    /* word-break: break-all; */
    /* font-family: 'ProductSans-Regular', serif; */
    font-size: medium;
    /* background-color: #202125; */
    display: inline-block;
    margin-top: 1vmax;
    color: #6ea0eb;
}

.theteam-org-team-tile-title {
    width: fit-content;
    height: fit-content;
    word-break: break-word;
    /* word-break: break-all; */
    font-family: 'ProductSans-Regular', serif;
    font-size: 1.5vh;
    background-color: #202125;
    display: inline-block;
    margin-top: 1vmax;
    color: #EDEDED;
    padding: 0.25vmax 0.8vmax;
    border-radius: 1.25rem;
}

.theteam-core-team-tile-subtitle {
    font-size: small;
    display: inline-block;
    /* margin-top: 1vh; */
    border-radius: 1.25rem;
}