.menusymbol-wrapper {
    font-size: large;
    background-color: #6ea0eb;
    width: max-content;
    text-align: center;
    padding: 0 5%;
    border-radius: 2rem;
    color: white;
    transition: 0.3s;
    /* box-shadow: 0 0 0 0.3vh #202125; */
}

.menusymbol-wrapper:hover {
    color: white;
    cursor: pointer;
}

.menu-offcanvas {
    transition: 0.3s !important;
}

.offcanvas-header {
    text-align: center !important;
}

.menu-offcanvas-title {
    /* font-size: 1.25vmax !important; */
    font-size: large;
    background-color: #6ea0eb;
    width: max-content;
    border-radius: 2rem;
    padding: 0 10%;
    color: white !important;
    /* box-shadow: 0 0 0 0.3vh #202125; */
    transform: translateZ(0);
}

.menu-offcanvas-entity {
    font-size: 150%;
    text-align: center;
    margin-bottom: 4vh;
    padding: 1vw;
    border-radius: 0.25vmax;
    transition: 0.3s;
    color: #6ea0eb;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.menu-offcanvas-entity:hover {
    background-color: #6ea0eb;
    color: white;
    cursor: pointer;
}