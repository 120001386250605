.footer-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 2vh !important;
    padding: 2vmax;
    background-color: #202125;
}

.footer-col {
    margin-top: 1vh !important;
}

.footer-signature {
    font-family: 'ProductSans-Bold', serif;
    font-size: 100%;
    text-align: center;
}

.footer-my-sign {
    font-family: 'ProductSans-Light', serif !important;
}

.link-sign {
    text-decoration: none;
    color: #5E6369;
    transition: 0.3s;
}

.link-sign:hover {
    color: white;
}

.footer-social {
    text-align: center !important;
}

.footer-social-symbol:hover {
    color: white !important;
}

.my-icon {
    color: white !important;
}