.home-wrapper {
    /* height: 100vh; */
    /* width: 100vw; */
}

.home-hero-title {
    margin-left: 2vw;
    margin-right: 2vw;
    font-size: 25vmin;
    word-wrap: break-word;
    line-height: 18vmin;
    text-align: left;
    padding: 10vmin;
    background-color: #6ea0eb;
    color: white;
    border-radius: 2.5rem;
}

.info-bar-wrapper {
    margin-top: 2vh;
    text-align: center;
    padding: 1vh 2vw;
    background-color: #6ea0eb;
    color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.hero-question-wrapper {
    margin-top: 2vh;
    text-align: center;
    font-size: 8vmin;
    /* margin-left: 2vw;
    margin-right: 2vw; */
    /* color: #6ea0eb; */
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.hero-join-now-wrapper {
    margin-top: 4vh;
    margin-bottom: 3vh;
    text-align: center;
    font-size: 8vmin;
    background-color: #6EA0EB;
    color: white;
    transition: 0.3s;
}

.hero-join-now-wrapper:hover {
    cursor: pointer;
    background-color: white;
    color: #6EA0EB;
    box-shadow: 0 0 0 0.3vh #6EA0EB;
}

.highlights-card-wrapper {
    margin-left: 2vw !important;
    margin-right: 2vw !important;
    /* margin-top: 1vh !important; */
}

.highlights-card-col {
    margin-top: 2vh !important;
}

.gl-red {
    background-color: #E57373;
    transition: 0.3s
}

.gl-red:hover {
    background-color: white;
    color: #E57373;
    cursor: pointer;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.gl-t-red {
    background-color: white;
    transition: 0.3s;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    color: #E57373 !important;
}

.gl-t-red:hover {
    background-color: #E57373;
    color: white !important;
    cursor: pointer;
}

.gl-blue {
    background-color: #6EA0EB;
    transition: 0.3s;
}

.gl-blue:hover {
    background-color: white;
    color: #6EA0EB;
    cursor: pointer;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.gl-t-blue {
    background-color: white;
    transition: 0.3s;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    color: #6EA0EB !important;
}

.gl-t-blue:hover {
    background-color: #6EA0EB;
    color: white !important;
    cursor: pointer;
}

.gl-green {
    background-color: #81C784;
    transition: 0.3s;
}

.gl-green:hover {
    background-color: white;
    color: #81C784;
    cursor: pointer;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.gl-t-green {
    background-color: white;
    transition: 0.3s;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    color: #81C784 !important;
}

.gl-t-green:hover {
    background-color: #81C784;
    color: white !important;
    cursor: pointer;
}

.gl-yellow {
    background-color: #FFB74D;
    transition: 0.3s;
}

.gl-yellow:hover {
    background-color: white;
    color: #FFB74D;
    cursor: pointer;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.gl-t-yellow {
    background-color: white;
    transition: 0.3s;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    color: #FFB74D !important;
}

.gl-t-yellow:hover {
    background-color: #FFB74D;
    color: white !important;
    cursor: pointer;
}

.card-wrapper {
    padding: 3%;
    color: white;
    border-radius: 0.85rem;
    height: 100%;
}

.card-title {
    font-size: 200%;
}

.card-description {
    font-size: 100%;
    /* color: white !important; */
}